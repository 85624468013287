import axios from 'axios'
import { Langage } from "../language";
import { appLanguage, titleNormalized } from "../select";
import { getRequestForGptHair } from '../utils/helper';
import { sendRecommendationEmail } from './api-conseil-hair';

const base_url = 'https://api.iux1.com/'
const gydtech_url = 'https://cactus.gydtech.io/'
const gpt_url = 'https://gpt-api.gydtech.io/'
const effimetrix_url = 'https://api.effimetrix.care/'
const getText = (key) => {
  return Langage[key][appLanguage]
}

export const uploadPicture = async (image) => {
  const formData = new FormData();
  formData.append('image', image, image.name || 'captured.png');
  const result = await axios({
    method: 'post',
    url: `${base_url}api-v2/picture/upload`,
    data: formData
  });

  return result.data
}

// upload 2 pictures to hairs api
export const uploadPictureForHairs = async (images, uid) => {
  const data = new FormData();
  data.set('hair',  images[0], images[0].name);
  data.set('skull', images[1], images[1].name);

  const result = await axios({
    method: 'put',
    url: `${gydtech_url}api/forms/${uid}/pics`,
    data
  });

  return result
}

export const processAnalysis = async (id) => {

  try {
    const result = await axios({
      method: 'put',
      url: `${base_url}api-v2/picture/${id}/analysis`,
      data: {
        "traitement": {
          "traitementRides": true,
          "traitementRegulation": true,
          "traitementSensibilite": true,
          "traitementEclat": true,
          "traitementHydratation": true,
          "traitementTaches": true,
          "traitementImperfections": true,
          "traitementPores": true
        },
        "age": 52 // todo
      }
    });
    return result.data
  } catch (error) {
    console.error(error)
  }
}

export const submit = async (form) => {

  let final = []
  let tmp = {}
  form.forEach((item, index) => {
    if (item.type === 'bot') {
      if (Object.keys(tmp).length > 0) {
        final.push(tmp)
        tmp = {}
      }
      tmp.question = item.message
      tmp.response = ''
      tmp.brief = null
      tmp.uid = item.id
    }
    else {
      tmp.response = tmp.response.concat("", item.message)
    }

  })

  try {
    const request = {
      session: final,
      version: 2
    }
    const response = await axios.post(`${gydtech_url}api/forms/skin`, request);
    if (response.data.id) {
      // todo stocker le id questoinner 
    }

  } catch (error) {
    console.error(error);
  }
};

export const submitBesoins = async (request, states, recommendation , langue) => {

 
  const form = states.messages;
  let final = [];
    final = [
      {
        "question": getText('age_q'),
        "response": states.age,
        "uid": -3,
        isSec: true
      },
      {
        "question": getText('gender_q'),
        "response": states.genre,
        "uid": -2,
        isSec: true
      },
      // {
      //   "question": getText('today_type'),
      //   "response": states.skinType,
      //   "uid": -1,
      //   isSec: true
      // }
    ]
  
  let tmp = {}
  form.forEach((item, index) => {
    if (item.type === 'bot') {
      if (Object.keys(tmp).length > 0) {
        final.push(tmp)
        tmp = {}
      }
      tmp.question = item.message
      tmp.response = ''
      tmp.brief = item.payload.brief || null
      tmp.besoin = item.payload.besoin || '_BEFORE'
      tmp.uid = item.payload.id || item.payload.uid
      tmp.isSec = item.payload.isSec
    }
    if (item.type === 'user') {
      tmp.response = tmp.response.concat("", item.message)
    }
  })
  final = final.sort((a, b) => a.uid - b.uid);
  final = final.filter(f => f.isSec);
  final = final.map(f => {
    delete f.isSec;
    if (f.brief === null) {
      delete f.brief;
    }
    if (titleNormalized === 'chatbot-hair') {
      delete f.besoin;
    }
    return f;
  });

  try {
    const request = {
      session: final.filter(f => f.uid !== 16),
      version: 2
    }
    let endpoint = 'besoins';
    let version = '';
    if (titleNormalized === 'chatbot-hair'){
      endpoint = 'hair';
      version = 'V3';
    }
      console.log(final);
    let response
    //console.log(request);
     response = await axios.post(`${gydtech_url}api/forms/${endpoint}`, request);
    /*  if (titleNormalized === 'chatbot-hair') {
      await uploadPictureForHairs(states.imgSelfie, response.data.id);
    } */
   
    // if (response.data.id) {
    //   const pharmaId = (window.location.href.includes('id=') ? window.location.href.split('id=')[1].split('&')[0] : '2085475');
    //   const followingId = localStorage.getItem('followingCactusId')
    //   // if (titleNormalized === 'chatbot-hair') { // todo recommendationGPT for demo (salon, just paris)
    //   //   const params = {
    //   //     first_name: states.fullName,
    //   //     to: states.email,
    //   //     url: `https://democactus-2.gydtech.io?mail=${states.email}&idQuestionner=${response.data.id}&token=${states.token}&pharmaId=${pharmaId}`
    //   //   }
    //   //   await sendRecommendationEmail(states.recommendationGPT, params)
    //   // window.location.href = `https://democactus-2.gydtech.io?mail=${states.email}&idQuestionner=${response.data.id}&token=${states.token}&pharmaId=${pharmaId}`;
    //   // } else {
    //   window.location.href = `https://democactus.gydtech.io?mail=${states.email}&idQuestionner=${states.id_form}&token=${states.token}&pharmaId=${pharmaId}&followingId=${followingId}`;
    //   // }
   //}

   if (titleNormalized === 'chatbot-hair') {
    let listId, templateId;
    if(langue = 'french'){
      listId = 13
      templateId = 24
    }
    if(langue = 'english'){
      listId = 14
      templateId = 56
    }
     // todo recommendationGPT for demo (salon, just paris)
    const pharmaId = (window.location.href.includes('id=') ? window.location.href.split('id=')[1].split('&')[0] : '2085475');
        const params = {
          first_name: states.fullName,
          to: states.email,
          url: `https://democactus.gydtech.io?mail=${states.email}&idQuestionner=${response.data.id}&token=${states.token}&pharmaId=${pharmaId}`,
          templateId
        }
        const contact = {
          email: states.email,
          recommandation_lien: `https://democactus.gydtech.io?mail=${states.email}&idQuestionner=${response.data.id}&token=${states.token}&pharmaId=${pharmaId}`,
          listId,
          langue,
          texte_conseils : recommendation,
          etat_cuirchevelu : final.find(item => item.uid === 3).response,
          etat_cheveux : final.find(item => item.uid === 6).response,
          longueur_cheveux : final.find(item => item.uid === 2).response,
          type_cheveux : final.find(item => item.uid === 45).response,
          DATE_DERNIER_DIAG_GYDBEAUTYCOIF : new Date(),
          TEXTE_RECO_SHAMP : null
        }
    
        await createContact(contact)
        await sendRecommendationEmail(recommendation, params)
      } 

   return response.data.id

  } catch (error) {
    console.error(error);
  }
};

export const goToMarketPlace = async(states) => {
  let id = localStorage.getItem('followingCactusId')
  await updatefollowingChat({ id : id, chat_id: states.id_form, step : true, num_step: 3 }, id);
  const pharmaId = (window.location.href.includes('id=') ? window.location.href.split('id=')[1].split('&')[0] : '2085475');
  const followingId = localStorage.getItem('followingCactusId')
  window.location.href = `https://democactus.gydtech.io?mail=${states.email}&idQuestionner=${states.id_form}&token=${states.token}&pharmaId=${pharmaId}&followingId=${followingId}`;
}

export const getQuestions = async (type = '') => {

  try {
    return await axios({
      method: 'get',
      url: `${gydtech_url}api/questions${type}`
    })
  } catch (error) {
    console.error(error)
  }

}

export const skinType = async (scores, age) => {

  try {
    const result = await axios({
      method: 'post',
      url: `${gydtech_url}api/skintype`,
      data: { scores, age }
    });

    return result.data
  } catch (error) {
    console.error(error)
  }

}

export const registerProfile = async (data) => {

  try {
    const result = await axios({
      method: 'post',
      url: `${effimetrix_url}profiles`,
      data: data
    });

    return result.data
  } catch (error) {
    console.error(error)
  }

}

export const createfollowingChat = async (data) => {

  try {
    const result = await axios({
      method: 'post',
      url: `${effimetrix_url}following_cactus`,
      data: data
    });

    return result.data
  } catch (error) {
    console.error(error)
  }

}

export const createOrganization = async (data) => {

  try {
    const result = await axios({
      method: 'post',
      url: `${effimetrix_url}profiles/user`,
      data: data
    });
    return result.data
  } catch (error) {
    console.error(error)
  }

}

export const updatefollowingChat = async (data, id) => {

  try {
    const result = await axios({
      method: 'put',
      url: `${effimetrix_url}following_cactus/${id}`,
      data: data
    });

    return result.data
  } catch (error) {
    console.error(error)
  }

}

export const findProfileByEmail = async (email) => {

  try {
    const result = await axios({
      method: 'get',
      url: `${effimetrix_url}profiles/find/${email}`
    });

    return result.data
  } catch (error) {
    console.error(error)
  }

}
// retoit le token d un profile en lui enviyant son id
export const getToken = async (id) => {

  try {
    const result = await axios({
      method: 'post',
      url: `${effimetrix_url}customers/token/${id}`
    });

    return result.data
  } catch (error) {
    console.error(error)
  }

}

export const getHairDresser = async (id) => {

  try {
    const result = await axios({
      method: 'get',
      url: `${effimetrix_url}client_hairDresser/${id}`
    });

    return result.data
  } catch (error) {
    console.error(error)
  }

}

/////////////////////////
// AI D-ID FACE & TALK //
/////////////////////////

const baseUrlDid = 'https://api.d-id.com';
const D_ID_API_TOKEN = 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ik53ek53TmV1R3ptcFZTQjNVZ0J4ZyJ9.eyJodHRwczovL2QtaWQuY29tL2ZlYXR1cmVzIjoidGFsa3MiLCJodHRwczovL2QtaWQuY29tL2N4X2xvZ2ljX2lkIjoiIiwiaXNzIjoiaHR0cHM6Ly9hdXRoLmQtaWQuY29tLyIsInN1YiI6Imdvb2dsZS1vYXV0aDJ8MTA1ODQ3NzUwODQyOTg2ODE0OTUyIiwiYXVkIjpbImh0dHBzOi8vZC1pZC51cy5hdXRoMC5jb20vYXBpL3YyLyIsImh0dHBzOi8vZC1pZC51cy5hdXRoMC5jb20vdXNlcmluZm8iXSwiaWF0IjoxNjg3NzE0MDU4LCJleHAiOjE2ODc4MDA0NTgsImF6cCI6Ikd6ck5JMU9yZTlGTTNFZURSZjNtM3ozVFN3MEpsUllxIiwic2NvcGUiOiJvcGVuaWQgcHJvZmlsZSBlbWFpbCByZWFkOmN1cnJlbnRfdXNlciB1cGRhdGU6Y3VycmVudF91c2VyX21ldGFkYXRhIG9mZmxpbmVfYWNjZXNzIn0.AVE6GK_VYHxh7M_r3cRpECy9aj9N_TsVMuWO82OsfPK1K41Nj6MXQp0859h6UUFGiOewKcm9UjN0Tv4kws4s-khxVTwlReb0BPEdKAXS1OBAGZ-VMm8wMLQPp83DaWlFRMpevhnySI_jsZkTmzOZEaLH3hBrQ1IrTrZvrkYHbEAng5EDDJv9rayHfi9O-RssoBjIBDbRLzGMz0hqzOWYnXfT4uTsbTSsDE5vHstkmmsZkvaKO6Y4ZWfgoAx2RIfuF1VxGMmhdt-reWcvEh-dFzpYdPRn5o1hg-1Y6zOMadcuD4rrQD0Dt-xTqh5MoSBnayJoW1_HHyAGabLfeCe7gA';

export const uploadPictureD_id = async (image) => {
  const formData = new FormData();
  formData.append('image', image, image.name || 'captured.png');
  const result = await axios({
    method: 'POST',
    url: `${baseUrlDid}/images`,
    headers: {
      accept: 'application/json',
      'content-type': 'multipart/form-data',
      authorization: D_ID_API_TOKEN
    },
    data: formData
  });

  if (result.data && result.data.url) {
    localStorage.setItem('MY_AI_PIC', result.data.url);
  }

  return create_a_talk();
}

export const create_a_talk = async () => {
  const result = await axios({
    method: 'POST',
    url: `${baseUrlDid}/talks`,
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      authorization: D_ID_API_TOKEN
    },
    data: {
      script: {
        type: 'text',
        subtitles: 'false',
        provider: { type: 'microsoft', voice_id: 'fr-FR-DeniseNeural' },
        ssml: 'false',
        input: 'Enchanté, Quel âge avez-vous ?'
      },
      config: { fluent: 'false', pad_audio: '0.0' },
      source_url: localStorage.getItem('MY_AI_PIC')
    }
  });

  if (result.data && result.data.id) {
    localStorage.setItem('MY_AI_ID', result.data.id);
  }

  return get_a_talk();
}

export const get_a_talk = async () => {
  const result = await axios({
    method: 'GET',
    url: `${baseUrlDid}/talks/${localStorage.getItem('MY_AI_ID')}`,
    headers: {
      accept: 'application/json',
      authorization: D_ID_API_TOKEN
    }
  });

  if (result.data && result.data.result_url) {
    localStorage.setItem('MY_AI_TALKING_FACE', result.data.result_url);
    document.querySelector('#ai-video').src = localStorage.getItem('MY_AI_TALKING_FACE');
    return result.data;
  } else {
    setTimeout(() => {
      return get_a_talk();
    }, 3000)
  }
}

// check if response related to question
export const relatedTo = async (data) => {

  try {
    const result = await axios({
      method: 'post',
      url: `${gydtech_url}api/ai/are-related`,
      data,
      timeout: 15000
    });
    return result.data
  } catch (error) {
    if (error.code === 'ECONNABORTED') {
      console.error('Request timed out');
      return  {related : true}
    }
    else 
      console.error(error)
      return  {related : true}
  }
}

export const moderation = async (data) => {

  try {
    const result = await axios({
      method: 'post',
      url: `${gydtech_url}api/ai/moderation`,
      data
    });
    return result.data
  } catch (error) {
    console.error(error)
  }
}

export const getProfile = async (id) => {
  const result = await axios({
    method: 'get',
    url: `https://app.gydtech.io/ws/establishment/${id}`
  });

  return result.data
}

export const getPicture = async (id) => {
  const result = await axios({
    method: 'get',
    url: `https://app.gydtech.io/ws/avatar/${id}`
  });

  return result.data
}

/// gpt conseil /////////////
export const createContact = async (data) => {

  try {
    const result = await axios({
      method: 'post',
      url: `${gpt_url}api/contact/create`,
      data
    });
    return result.data
  } catch (error) {
    console.error(error)
  }
}
