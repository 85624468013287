import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { getHairDresser } from '../../api/service';

export default function ResponsiveDialog({data}) {
  const [open, setOpen] = useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [hairDresser, setHairDresser] = useState('');

  const handleChange = (event) => {
    localStorage.setItem('hairDresser', event.target.value);
    setHairDresser(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
            Veuillez choisir le coiffeur de votre choix:
          </DialogContentText>
          <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Coiffeur</InputLabel>
        <Select
  labelId="demo-simple-select-label"
  id="demo-simple-select"
  value={hairDresser}
  label="Coiffeur"
  onChange={handleChange}
>
  {data && data.map((data, index) => (
    <MenuItem key={index} value={data.hairDresser_name}>
      {data.hairDresser_name}
    </MenuItem>
  ))}
</Select>
      </FormControl>
    </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
           Valider
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
